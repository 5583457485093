import { ActionsObservable, Epic, ofType, StateObservable } from 'redux-observable';
import { of } from 'rxjs'
import { catchError, map, switchMap } from 'rxjs/operators'
import { StatisticsService } from '../../Domain/Gateway/Statistics.service';
import { GetAppStatsState } from "../../Config/State";
import { GET_SESSION_STATS, GetSessionStatsAction } from "./actionTypes";
import { getSessionStatsFailed, getSessionStatsSucceeded } from "./actions";
import { DeviceData } from "../../Domain/Entity/DeviceData";

export const getSessionStatsEpic: Epic = (action$: ActionsObservable<GetSessionStatsAction>,
                                          store: StateObservable<GetAppStatsState>,
                                          {statisticsService}: { statisticsService: StatisticsService }) =>
    action$.pipe(
        ofType(GET_SESSION_STATS),
        switchMap((action) => statisticsService.getSessionStats(action.payload.startDate, action.payload.endDate)
            .pipe(
                map((stats: DeviceData) => getSessionStatsSucceeded(stats)),
                catchError(error => of(getSessionStatsFailed(error)))
            )
        )
    )
