import React, { PureComponent, ReactNode } from "react";
import { Box, Grid } from '@mui/material';
import { CartesianData } from "../../../Domain/Entity/CartesianData";
import { XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, AreaChart, Area } from 'recharts';
import { ApplicationContext } from "../../../../Configuration/Application.context";
import { StatisticsGridTitle } from "./Title";
import { Theme } from "../../../../Common/Config/Theme";
import { DeviceData } from "../../../Domain/Entity/DeviceData";
import { MultivariateData } from "../../../Domain/Entity/MultivariateData";
import { MultivariateDataBuilder } from "../../../Domain/Builder/MultivariateData.builder";
import { i18n } from "../../../../Configuration/I18n";

const moment = ApplicationContext.getInstance().momentJs()

interface Props {
    title: string;
    stats: DeviceData | null;
    children: JSX.Element;
}

interface State {
    filter: string;
}

export class StatisticsAreaChart extends PureComponent<Props, State> {

    constructor(props: Props) {
        super(props)
        this.state = {
            filter: 'all'
        }
    }

    render(): ReactNode {
        return (
            <Grid container spacing={3} mb={3}>
                <Grid item xs={12}>
                    <Box className={'statistics-grid'}>
                        <StatisticsGridTitle title={this.props.title} info={this.props.title}>
                            {this.props.children}
                        </StatisticsGridTitle>

                        <ResponsiveContainer width="100%" height={300}>
                            <AreaChart width={500}
                                       height={300}
                                       style={{fontFamily: 'sans-serif', fontSize: 12}}
                                       data={this.props.stats ? this.getFormattedData(this.props.stats) : []}
                                       margin={{top: 20, right: 40, bottom: 20, left: 0}}>

                                <CartesianGrid strokeDasharray="3 3"/>
                                <XAxis dataKey="label"/>
                                <YAxis/>
                                <Tooltip/>

                                <Area type="monotone" dataKey="valueA"
                                      name={i18n.statistics.mobile_sessions_number}
                                      stroke={Theme.darkBlue}
                                      fill={Theme.darkBlue}/>

                                <Area type="monotone" dataKey="valueB"
                                      name={i18n.statistics.web_sessions_number}
                                      stroke={Theme.lightOrange}
                                      fill={Theme.lightOrange}/>
                            </AreaChart>
                        </ResponsiveContainer>
                    </Box>
                </Grid>
            </Grid>
        )
    }

    getFormattedData(stats: DeviceData): MultivariateData[] {
        if (stats.mobile.length === 0) {
            // Get formatted "web" stats
            return this.mapDataByDeviceType('web', stats.desktop)
        } else if (stats.desktop.length === 0) {
            // Get formatted "mobile" stats
            return this.mapDataByDeviceType('mobile', stats.mobile)
        } else if (stats.mobile.length >= stats.desktop.length) {
            // Get formatted mobile and web stats using "mobile" dates
            return this.mapMultipleDataByDeviceType('mobile', stats.mobile, stats.desktop)
        } else {
            // Get formatted mobile and web stats using "web" dates
            return this.mapMultipleDataByDeviceType('web', stats.desktop, stats.mobile)
        }
    }

    mapDataByDeviceType(type: 'web' | 'mobile', stats: CartesianData[]): MultivariateData[] {
        return stats.map(item => {
            return new MultivariateDataBuilder()
                .withLabel(moment(item.label, 'YYYY-MM-DD').format('DD MMM'))
                .withValueA(type === 'web' ? 0 : item.value)
                .withValueB(type === 'web' ? item.value : 0)
                .build()
        })
    }

    mapMultipleDataByDeviceType(maxType: 'web' | 'mobile', maxStats: CartesianData[], minStats: CartesianData[]): MultivariateData[] {
        let j = 0;
        const multivariateStats: MultivariateData[] = []

        for (let i = 0; i < maxStats.length; i++) {
            let value = 0

            if (maxStats[i].label === minStats[j].label) {
                value = minStats[j].value
                j++;
            }

            multivariateStats.push(
                new MultivariateDataBuilder()
                    .withLabel(moment(maxStats[i].label, 'YYYY-MM-DD').format('DD MMM'))
                    .withValueA(maxType === 'mobile' ? maxStats[i].value : value)
                    .withValueB(maxType === 'web' ? maxStats[i].value : value)
                    .build()
            )
        }
        return multivariateStats
    }
}
