import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators'
import { SecuredObservableAjaxHttpClient } from "../../../../Common/Adapters/Secondaries/Real/SecuredObservableAjax.httpClient";
import { AuthenticationDependenciesFactory } from "../../../../Authentication/Config/Dependencies.factory";
import { StatisticsService } from "../../../Domain/Gateway/Statistics.service";
import { CartesianData } from "../../../Domain/Entity/CartesianData";
import { StatisticsMapper } from "./mapper/Statistics.mapper";
import { CartesianDataDTO } from "./DTO/CartesianData.DTO";
import { SeriesDataDTO } from "./DTO/SeriesData.DTO";
import { SerieData } from "../../../Domain/Entity/SerieData";
import { AdventureData } from "../../../Domain/Entity/AdventureData";
import { AdventureDataDTO } from "./DTO/AdventureData.DTO";
import { EmployeeData } from "../../../Domain/Entity/EmployeeData";
import { EmployeeDataDTO } from "./DTO/EmployeeData.DTO";
import { AdventureType } from "../../../../Adventure/Domain/Entity/Adventure";
import { DeviceData } from "../../../Domain/Entity/DeviceData";
import { DeviceDataDTO } from "./DTO/deviceData.DTO";

export class ApiStatisticsService implements StatisticsService {

    getLoginStats(startDate: string, endDate: string): Observable<CartesianData[]> {
        const url = process.env.REACT_APP_API_URL + '/v1/back/stats/successLogin?startDate=' + startDate + '&endDate=' + endDate
        return new SecuredObservableAjaxHttpClient(AuthenticationDependenciesFactory.getAuthenticationRepository())
            .get<{ data: CartesianDataDTO[] }>(url)
            .pipe(
                map((response: { data: CartesianDataDTO[] }) => StatisticsMapper.mapDatesToCartesianData(response.data, startDate)),
                catchError(err => throwError(err.status.toString()))
            )
    }

    getSessionStats(startDate: string, endDate: string): Observable<DeviceData> {
        const url = process.env.REACT_APP_API_URL + '/v1/back/stats/startSession?startDate=' + startDate + '&endDate=' + endDate
        return new SecuredObservableAjaxHttpClient(AuthenticationDependenciesFactory.getAuthenticationRepository())
            .get<{ data: DeviceDataDTO }>(url)
            .pipe(
                map((response: { data: DeviceDataDTO }) => StatisticsMapper.mapDatesToDeviceData(response.data, startDate)),
                catchError(err => throwError(err.status.toString()))
            )
    }

    getAvatarStats(): Observable<CartesianData[]> {
        const url = process.env.REACT_APP_API_URL + '/v1/back/stats/avatars'
        return new SecuredObservableAjaxHttpClient(AuthenticationDependenciesFactory.getAuthenticationRepository())
            .get<{ data: CartesianDataDTO[] }>(url)
            .pipe(
                map((response: { data: CartesianDataDTO[] }) => StatisticsMapper.mapToCartesianData(response.data)),
                catchError(err => throwError(err.status.toString()))
            )
    }

    getMcqStats(adventureId: string): Observable<SerieData[]> {
        const url = process.env.REACT_APP_API_URL + '/v1/back/stats/mcq/' + adventureId
        return new SecuredObservableAjaxHttpClient(AuthenticationDependenciesFactory.getAuthenticationRepository())
            .get<{ data: SeriesDataDTO[] }>(url)
            .pipe(
                map((response: { data: SeriesDataDTO[] }) => StatisticsMapper.mapToSerieData(response.data)),
                catchError(err => throwError(err.status.toString()))
            )
    }

    getSpeedQuizQuestionStats(speedQuizId: string): Observable<SerieData[]> {
        const url = process.env.REACT_APP_API_URL + '/v1/back/stats/speedQuiz/' + speedQuizId + '/question'
        return new SecuredObservableAjaxHttpClient(AuthenticationDependenciesFactory.getAuthenticationRepository())
            .get<{ data: SeriesDataDTO[] }>(url)
            .pipe(
                map((response: { data: SeriesDataDTO[] }) => StatisticsMapper.mapToSerieData(response.data)),
                catchError(err => throwError(err.status.toString()))
            )
    }

    getAdventureStats(adventureId: string, adventureType: AdventureType): Observable<AdventureData> {
        const url = process.env.REACT_APP_API_URL + '/v1/adventure/' + adventureId + '/score'
        return new SecuredObservableAjaxHttpClient(AuthenticationDependenciesFactory.getAuthenticationRepository())
            .get<{ data: AdventureDataDTO }>(url)
            .pipe(
                map((response: { data: AdventureDataDTO }) => StatisticsMapper.mapToAdventureData(response.data, adventureType)),
                catchError(err => throwError(err.status.toString()))
            )
    }

    getSpeedQuizResponseStats(speedQuizId: string): Observable<CartesianData[]> {
        const url = process.env.REACT_APP_API_URL + '/v1/back/stats/speedQuiz/' + speedQuizId + '/response'
        return new SecuredObservableAjaxHttpClient(AuthenticationDependenciesFactory.getAuthenticationRepository())
            .get<{ data: CartesianDataDTO[] }>(url)
            .pipe(
                map((response: { data: CartesianDataDTO[] }) => StatisticsMapper.mapToFormattedCartesianData(response.data)),
                catchError(err => throwError(err.status.toString()))
            )
    }

    getSpeedQuizPlayerStats(speedQuizId: string): Observable<CartesianData[]> {
        const url = process.env.REACT_APP_API_URL + '/v1/back/stats/speedQuiz/' + speedQuizId + '/player/stat'
        return new SecuredObservableAjaxHttpClient(AuthenticationDependenciesFactory.getAuthenticationRepository())
            .get<{ data: CartesianDataDTO[] }>(url)
            .pipe(
                map((response: { data: CartesianDataDTO[] }) => StatisticsMapper.mapToCartesianData(response.data)),
                catchError(err => throwError(err.status.toString()))
            )
    }


    getSpeedQuizPlayerCount(speedQuizId: string): Observable<number> {
        const url = process.env.REACT_APP_API_URL + '/v1/back/stats/speedQuiz/' + speedQuizId + '/player/count'
        return new SecuredObservableAjaxHttpClient(AuthenticationDependenciesFactory.getAuthenticationRepository())
            .get<{ data: number }>(url)
            .pipe(
                map((response: { data: number }) => response.data),
                catchError(err => throwError(err.status.toString()))
            )
    }

    getMessageStats(messageId: string): Observable<CartesianData[]> {
        const url = process.env.REACT_APP_API_URL + '/v1/back/stats/newsCatch/' + messageId
        return new SecuredObservableAjaxHttpClient(AuthenticationDependenciesFactory.getAuthenticationRepository())
            .get<{ data: CartesianDataDTO[] }>(url)
            .pipe(
                map((response: { data: CartesianDataDTO[] }) => StatisticsMapper.mapToAccumulatedCartesianData(response.data)),
                catchError(err => throwError(err.status.toString()))
            )
    }

    getAdventureSubscriptionStats(adventureId: string): Observable<SerieData[]> {
        const url = process.env.REACT_APP_API_URL + '/v1/back/stats/adventure/' + adventureId + '/subscription'
        return new SecuredObservableAjaxHttpClient(AuthenticationDependenciesFactory.getAuthenticationRepository())
            .get<{ data: SeriesDataDTO[] }>(url)
            .pipe(
                map((response: { data: SeriesDataDTO[] }) => StatisticsMapper.mapToSerieData(response.data)),
                catchError(err => throwError(err.status.toString()))
            )
    }

    getEmployeeStats(): Observable<EmployeeData> {
        const url = process.env.REACT_APP_API_URL + '/v1/back/stats/users'
        return new SecuredObservableAjaxHttpClient(AuthenticationDependenciesFactory.getAuthenticationRepository())
            .get<{ data: EmployeeDataDTO }>(url)
            .pipe(
                map((response: { data: EmployeeDataDTO }) => StatisticsMapper.mapToEmployeeData(response.data)),
                catchError(err => throwError(err.status.toString()))
            )
    }

    getBadgesStats(): Observable<SerieData[]> {
        const url = process.env.REACT_APP_API_URL + '/v1/back/stats/badges'
        return new SecuredObservableAjaxHttpClient(AuthenticationDependenciesFactory.getAuthenticationRepository())
            .get<{ data: SeriesDataDTO[] }>(url)
            .pipe(
                map((response: { data: SeriesDataDTO[] }) => StatisticsMapper.mapToSerieData(response.data)),
                catchError(err => throwError(err.status.toString()))
            )
    }

    getPageStats(): Observable<CartesianData[]> {
        const url = process.env.REACT_APP_API_URL + '/v1/back/stats/pageDisplay'
        return new SecuredObservableAjaxHttpClient(AuthenticationDependenciesFactory.getAuthenticationRepository())
            .get<{ data: CartesianDataDTO[] }>(url)
            .pipe(
                map((response: { data: CartesianDataDTO[] }) => StatisticsMapper.mapToCartesianData(response.data)),
                catchError(err => throwError(err.status.toString()))
            )
    }

}
