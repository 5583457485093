import React, { PureComponent, ReactNode } from 'react'
import { Adventure } from "../../../Domain/Entity/Adventure";
import { i18n } from "../../../../Configuration/I18n";
import { Alert, Box, Button, CircularProgress } from "@mui/material";
import { PageTitle } from "../../../../Common/Adapters/Primaries/Components/PageTitle";
import { AdventureDetailsBlock } from "./components/AdventureDetails.block";
import { MCQSettingsBlock } from "./components/MCQSettings.block";
import { MCQQuestionsBlock } from "./components/MCQQuestion/MCQQuestions.block";
import { AdventureFormType } from "./type/AdventureFormType";
import { AdventureFormValidation } from "./AdventureFormValidation";
import { MCQ } from "../../../Domain/Entity/MCQ";
import { AdventureBuilder } from "../../../Domain/Builder/Adventure.builder";
import { ApplicationContext } from "../../../../Configuration/Application.context";
import { MCQBuilder } from "../../../Domain/Builder/MCQ.builder";
import { MCQQuestion } from "../../../Domain/Entity/MCQQuestion";
import { MCQQuestionBuilder } from "../../../Domain/Builder/MCQQuestion.builder";
import { RouteComponentProps } from "react-router";

const moment = ApplicationContext.getInstance().momentJs()

interface Props extends RouteComponentProps {
    loading: boolean;
    addAdventure: (adventure: Adventure, mcq: MCQ) => void;
    error: string | undefined
    setTopBarTitle: (title: string) => void
}

interface State extends AdventureFormType {
    error: boolean
    errorMessage: string
}

export class AddAdventureContainer extends PureComponent<Props, State> {

    constructor(props: Props) {
        super(props)
        this.state = {
            type        : 'INTER_AGENCE',
            name        : '',
            details     : '',
            picture     : undefined,
            pictureName : undefined,
            startDate   : '',
            endDate     : '',
            enabled     : true,
            reward      : 0,
            MCQSettings : {
                title    : '',
                picture  : undefined,
                reward   : 0,
                questions: []
            },
            error       : false,
            errorMessage: ''

        }
    }

    componentDidMount() {
        this.props.setTopBarTitle(i18n.adventure.adventure_title)
    }

    componentDidUpdate(prevProps: Props) {
        if (this.props.error && prevProps.error === undefined)
            this.setState({errorMessage: i18n.adventure.error_add_adventure})
        if (this.props.loading === false && prevProps.loading === true && this.props.error === undefined)
            this.props.history.push('/adventure-list')
    }

    render(): ReactNode {

        return (
            <Box>
                <PageTitle title={i18n.adventure.add_adventure}/>
                <Box component="form" noValidate autoComplete="off">
                    <AdventureDetailsBlock adventure={this.state}
                                           error={this.state.error}
                                           onChange={(key, value): void => {
                                               this.setState({
                                                   ...this.state, [key]: value
                                               })
                                           }}/>
                    <MCQSettingsBlock MCQSettings={this.state.MCQSettings}
                                      error={this.state.error}
                                      onChange={(key, value): void => {
                                          this.setState({
                                              MCQSettings: {
                                                  ...this.state.MCQSettings, [key]: value
                                              }
                                          })
                                      }}/>
                    <MCQQuestionsBlock error={this.state.error}
                                       questions={this.state.MCQSettings.questions}
                                       onChange={(value): void => {
                                           this.setState({
                                               MCQSettings: {
                                                   ...this.state.MCQSettings, questions: value
                                               }
                                           })
                                       }}/>

                    {this.state.errorMessage !== '' ?
                        <Alert severity="error">{this.state.errorMessage}</Alert> : null}
                    <Button variant="contained" onClick={() => this.addAdventure()}>
                        {i18n.adventure.save}
                    </Button>
                </Box>
                {this.props.loading ?
                    <Box sx={{display: 'flex', justifyContent: 'center'}}><CircularProgress/></Box> : null}
            </Box>
        )
    }

    addAdventure() {
        this.setState({error: AdventureFormValidation.validate(this.state)}, () => {
            if (!this.state.error) {
                const mcqQuestionList: MCQQuestion[] = []
                this.state.MCQSettings.questions.map(question => {
                    mcqQuestionList.push(new MCQQuestionBuilder()
                        .fromMCQQuestion(question)
                        .withPicture(this.state.MCQSettings.picture)
                        .build())
                })
                const adventure = new AdventureBuilder()
                    .withName(this.state.name)
                    .withType(this.state.type)
                    .withDetails(this.state.details)
                    .withStartDate(moment(this.state.startDate, 'YYYY-MM-DDTHH:mm').format())
                    .withEndDate(moment(this.state.endDate, 'YYYY-MM-DDTHH:mm').format())
                    .withReward(this.state.reward)
                    .withEnabled(this.state.enabled)
                if (this.state.picture)
                    adventure.withPicture(this.state.picture)

                const mcq = new MCQBuilder()
                    .withTitle(this.state.MCQSettings.title)
                    .withReward(this.state.MCQSettings.reward)
                    .withQuestions(mcqQuestionList)
                    .build()
                this.props.addAdventure(adventure.build(), mcq)
            }
        })
    }
}
