import {
    GetAdventureStatsAction,
    GetAdventureStatsActionTypes,
    GET_ADVENTURE_STATS,
    GET_ADVENTURE_STATS_FAILED, GET_ADVENTURE_STATS_SUCCEEDED
} from './actionTypes';
import { AdventureData } from "../../Domain/Entity/AdventureData";
import { AdventureType } from "../../../Adventure/Domain/Entity/Adventure";

export const getAdventureStats = (adventureId: string, adventureType: AdventureType): GetAdventureStatsAction => ({
    type   : GET_ADVENTURE_STATS,
    payload: {adventureId, adventureType}
})

export const getAdventureStatsFailed = (error: string): GetAdventureStatsActionTypes => ({
    type   : GET_ADVENTURE_STATS_FAILED,
    payload: error
})

export const getAdventureStatsSucceeded = (adventure: AdventureData): GetAdventureStatsActionTypes => ({
    type   : GET_ADVENTURE_STATS_SUCCEEDED,
    payload: adventure
})
