import React, { PureComponent, ReactNode } from 'react'
import { Box, Typography } from "@mui/material";
import { i18n } from "../../../../../Configuration/I18n";

interface Props {
    webUsers: number;
    mobileUsers: number;
}

export class UsersStatsNumberCard extends PureComponent<Props> {

    render(): ReactNode {
        return (
            <Box className={'statistics-grid card card-text'}>
                <Box>
                    <Box className={'d-flex'}>
                        <Typography>{this.props.mobileUsers + this.props.webUsers}</Typography>
                        <Typography component={'span'}>{i18n.statistics.users}</Typography>
                    </Box>

                    <Typography className={'light-style'} component={'span'}>
                        <Typography component={'b'}>{this.props.mobileUsers}</Typography> {i18n.statistics.mobile} et
                        <Typography component={'b'}> {this.props.webUsers}</Typography> {i18n.statistics.web}
                    </Typography>
                </Box>
            </Box>
        )
    }
}
