import React, { PureComponent, ReactNode } from "react";
import { Box, Grid } from '@mui/material';
import { CartesianData } from "../../../Domain/Entity/CartesianData";
import {
    XAxis,
    YAxis,
    CartesianGrid,
    ResponsiveContainer,
    Area, ComposedChart, Line, Tooltip
} from 'recharts';
import { ApplicationContext } from "../../../../Configuration/Application.context";
import { StatisticsGridTitle } from "./Title";
import { i18n } from "../../../../Configuration/I18n";
import { Theme } from "../../../../Common/Config/Theme";

const moment = ApplicationContext.getInstance().momentJs()

interface Props {
    title: string;
    stats: CartesianData[] | null;
    limit: number
    limitLabel: string
    valueLabel: string
    children?: ReactNode
    showLimit: boolean
}

export class AreaChartWithLimit extends PureComponent<Props> {

    render(): ReactNode {
        return (
            <Grid item xs={12}>
                <Box className={'statistics-grid'}>
                    <StatisticsGridTitle title={this.props.title} info={this.props.title}>
                        {this.props.children}
                    </StatisticsGridTitle>

                    <ResponsiveContainer width="100%" height={300}>
                        <ComposedChart width={500}
                                       height={300}
                                       style={{ fontFamily: 'sans-serif', fontSize: 12 }}
                                       data={this.getFormattedData()}
                                       margin={{ top: 20, right: 40, bottom: 20, left: 0 }}>

                            <CartesianGrid strokeDasharray="3 3"/>
                            <XAxis dataKey="label"/>
                            <YAxis/>
                            <Tooltip/>

                            {this.renderLimitChart()}

                            {this.props.stats !== null && this.props.stats.length === 0 ?
                                <text fill={'#891204'}
                                      fontSize={20}
                                      textAnchor="middle" x={'50%'}
                                      y={'50%'}>{i18n.statistics.no_data}</text>
                                : null
                            }
                            <Area type="monotone"
                                  dataKey="value"
                                  stroke="#FFB547"
                                  strokeWidth={2}
                                  fill="#FFB547"
                                  name={this.props.valueLabel}/>
                        </ComposedChart>
                    </ResponsiveContainer>
                </Box>
            </Grid>
        )
    }

    getFormattedData() {
        if (this.props.stats)
            return this.props.stats.map(stat => ({
                label: moment(stat.label, 'YYYY-MM-DD').format('DD MMM'),
                value: stat.value,
                goal : this.props.limit
            }))
        else
            return []
    }

    renderLimitChart() {
        if (this.props.showLimit)
            return <Line type="monotone"
                         dataKey="goal"
                         stroke={Theme.darkBlue}
                         dot={<Box/>}
                         name={this.props.limitLabel}/>
    }
}
