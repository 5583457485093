import React, { PureComponent, ReactNode } from 'react'
import { Box, Grid } from "@mui/material";
import { StatisticsCard } from "../../Component/Card";
import { i18n } from "../../../../../Configuration/I18n";
import { EmployeeData } from "../../../../Domain/Entity/EmployeeData";
import { AreaChartWithLimit } from "../../Component/AreaChartWithLimit";
import { PeriodFilter } from "./PeriodFilter";
import { CartesianData } from "../../../../Domain/Entity/CartesianData";
import { UsersStatsPieChart } from "./UsersStats.pie.chart";
import { UsersStatsNumberCard } from "./UsersStats.number.card";


interface Props {
    employeeStats: EmployeeData | null
    usersStats: CartesianData[] | null;
    onFilter: (value: string) => void
}

interface State {
    filterValue: string
}

export class UsersStats extends PureComponent<Props, State> {

    constructor(props: Props) {
        super(props)
        this.state = {
            filterValue: 'all'
        }
    }

    render(): ReactNode {
        return (
            <Box>
                <Grid container spacing={3} mb={3}>
                    <Grid item xs={12} lg={4}>
                        <UsersStatsPieChart title={i18n.statistics.subscription_rate}
                                            webUsers={this.props.employeeStats?.getWebUsersCount() ?? 0}
                                            mobileUsers={this.props.employeeStats?.getMobileUsersCount() ?? 0}
                                            total={this.props.employeeStats?.getEmployeeCount()}/>
                    </Grid>

                    <Grid item xs={12} lg={4}>
                        <UsersStatsNumberCard webUsers={this.props.employeeStats ? this.props.employeeStats.getWebUsersCount() : 0}
                                              mobileUsers={this.props.employeeStats ? this.props.employeeStats.getMobileUsersCount() : 0}/>
                    </Grid>

                    <Grid item xs={12} lg={4}>
                        <StatisticsCard title={i18n.statistics.employees}
                                        value={this.props.employeeStats ? this.props.employeeStats.getEmployeeCount().toString() : '0'}/>
                    </Grid>
                </Grid>

                <Grid container spacing={3} mb={3}>
                    <AreaChartWithLimit title={i18n.statistics.cumulative_of_new_users}
                                        stats={this.props.usersStats ? this.props.usersStats : null}
                                        limitLabel={i18n.statistics.employee_number}
                                        valueLabel={i18n.statistics.users_number}
                                        showLimit={this.state.filterValue === 'all'}
                                        limit={this.props.employeeStats ? this.props.employeeStats.getEmployeeCount() : 0}>

                        <PeriodFilter onFilter={value => {
                            this.setState({filterValue: value})
                            this.props.onFilter(value)
                        }}/>
                    </AreaChartWithLimit>
                </Grid>
            </Box>
        )
    }

}
