import React, { PureComponent, ReactNode } from 'react'
import { AdventureData } from "../../../../Domain/Entity/AdventureData";
import { CartesianData } from "../../../../Domain/Entity/CartesianData";
import { AdventureType } from "../../../../../Adventure/Domain/Entity/Adventure";
import { StandardAdventureProgressStats } from "./StandardAdventure/StandardAdventureProgressStats";
import { InterAgencyAdventureProgressStats } from "./InterAgencyAdventure/InterAgencyAdventureProgressStats";

interface Props {
    adventureStats: AdventureData
    adventureType: AdventureType | undefined
}

export class AdventureProgressStats extends PureComponent<Props> {

    render(): ReactNode {
        if (this.props.adventureType === 'STANDARD') {
            return (
                <StandardAdventureProgressStats adventureStats={this.props.adventureStats}/>
            )
        } else if (this.props.adventureType === 'INTER_AGENCE') {
            return (
                <InterAgencyAdventureProgressStats adventureStats={this.props.adventureStats}/>
            )
        }
        return <></>
    }

    getRateActivatedClientStats(): CartesianData[] {
        const activatedClientGoal = this.props.adventureStats ? ((this.props.adventureStats.totalActivatedClient / this.props.adventureStats.totalActivatedClientGoal) * 100) : 0
        const activatedClient = this.props.adventureStats ? (((this.props.adventureStats.totalActivatedClientGoal - this.props.adventureStats.totalActivatedClient) / this.props.adventureStats.totalActivatedClientGoal) * 100) : 0
        return [new CartesianData('activatedClientGoal', activatedClientGoal),
            new CartesianData('activatedClient', activatedClient)
        ]
    }
}
