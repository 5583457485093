import React, { PureComponent } from 'react';
import { Grid } from "@mui/material";
import { StatisticsMultiBarChart } from "../../../Component/MultiBarChart";
import { i18n } from "../../../../../../Configuration/I18n";
import { AdventureData } from "../../../../../Domain/Entity/AdventureData";

interface Props {
    adventureStats: AdventureData;
}

export class InterAgenceAdventureScore extends PureComponent<Props> {
    render() {
        return (
            <Grid container spacing={6} mb={6}>
                <StatisticsMultiBarChart title={i18n.statistics.agency_scores}
                                         dataType={'CURRENCY'}
                                         size={12}
                                         stats={this.props.adventureStats.agenciesByRegion}/>
            </Grid>
        );
    }
}

