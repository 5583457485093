import { CartesianData } from "./CartesianData";

export class DeviceData {
    constructor(
        protected _mobile: CartesianData[],
        protected _desktop: CartesianData[]
    ) {
    }

    get mobile(): CartesianData[] {
        return this._mobile;
    }

    get desktop(): CartesianData[] {
        return this._desktop;
    }
}
