import React, { PureComponent, ReactNode } from 'react'
import { EmployeeData } from "../../../../Domain/Entity/EmployeeData";
import { i18n } from "../../../../../Configuration/I18n";
import { Grid } from "@mui/material";
import { HorizontalBarChart } from "../../Component/HorizontalBarChart";
import { StatisticsPieChart } from "../../Component/PieChart";
import { PieChartColors } from "../../../../Config/Theme";
import { Region } from "../../../../../Agence/Domain/Entities/Region";
import { CartesianData } from "../../../../Domain/Entity/CartesianData";
import { MultivariateData } from "../../../../Domain/Entity/MultivariateData";
import { CartesianDataBuilder } from "../../../../Domain/Builder/CartesianData.builder";

interface Props {
    employeeStats: EmployeeData | null
    regions: Region[] | null
}

export class StatsByRegion extends PureComponent<Props> {

    render(): ReactNode {
        return (
            <Grid container spacing={3} mb={3}>
                <Grid item xs={12} lg={7}>
                    <HorizontalBarChart title={i18n.statistics.subscription_rate_by_region}
                                        stats={this.getDataByRegion()}/>
                </Grid>

                <StatisticsPieChart title={i18n.statistics.employees_region}
                                    info={i18n.statistics.employees_region}
                                    stats={this.getOrderedValues()}
                                    colors={PieChartColors}
                                    size={5}/>
            </Grid>
        )
    }

    getOrderedValues(): CartesianData[] {
        return this.getUsersByRegion().sort((a, b) => b.value - a.value)
    }

    getUsersByRegion(): CartesianData[] {
        if (this.props.employeeStats) {
            const allUsers = this.getCombinedUsers(this.props.employeeStats)

            return allUsers.map(usersStat => {
                const region = this.props.regions?.find(region => region.regionCode === usersStat.label)
                const label = this.renderLabel(region, usersStat.label)
                return new CartesianData(label, usersStat.value)
            });
        }
        return []
    }

    getDataByRegion(): MultivariateData[] {
        if (this.props.employeeStats) {
            const allUsers = this.getCombinedUsers(this.props.employeeStats)

            return this.props.employeeStats.employees.map((employee: CartesianData) => {
                const usersStat: CartesianData | undefined = allUsers.find(user => user.label === employee.label)

                const totalNumberOfEmployee = usersStat ? usersStat.value + employee.value : employee.value

                const region = this.props.regions?.find(region => region.regionCode === employee.label)
                const label = this.renderLabel(region, employee.label)

                const employeeValue = this.percentEmployeeValue(employee.value, totalNumberOfEmployee)
                const userValue = this.percentUserValue(usersStat, totalNumberOfEmployee)
                return new MultivariateData(label, employeeValue, userValue)
            })
        }
        return []
    }

    renderLabel(region: Region | undefined, label: string) {
        if (region)
            return region.regionLabel + ' (' + region.regionName + ')'
        else
            return '(' + label + ')'
    }

    private percentEmployeeValue(value: number, total: number): number {
        return Math.floor((value / total) * 100)
    }

    private percentUserValue(stat: CartesianData | undefined, total: number): number {
        if (stat)
            return Math.ceil((stat.value / total) * 100)
        else
            return 0
    }

    private getCombinedUsers(users: EmployeeData) {
        const mobileUsers = [...users.mobileUsers]
        const webUsers = [...users.webUsers]
        const duplicatedUsersIndex: number[] = [];

        for (let i = 0; i < mobileUsers.length; i++) {
            for (let j = 0; j < webUsers.length; j++) {
                if (mobileUsers[i].label === webUsers[j].label) {
                    mobileUsers[i] = new CartesianDataBuilder()
                        .withLabel(mobileUsers[i].label)
                        .withValue(mobileUsers[i].value + webUsers[j].value)
                        .build()
                    duplicatedUsersIndex.push(j)
                }
            }
        }

        duplicatedUsersIndex.sort()
        duplicatedUsersIndex.reverse()
        for (let k = 0; k < duplicatedUsersIndex.length; k++) {
            webUsers.splice(duplicatedUsersIndex[k], 1)
        }

        return [...mobileUsers, ...webUsers];
    }
}
