import { DeviceData } from "../../Domain/Entity/DeviceData";

export const GET_SESSION_STATS = 'GET_SESSION_STATS'
export const GET_SESSION_STATS_FAILED = 'GET_SESSION_STATS_FAILED'
export const GET_SESSION_STATS_SUCCEEDED = 'GET_SESSION_STATS_SUCCEEDED'

export interface GetSessionStatsAction {
    type: typeof GET_SESSION_STATS;
    payload: {
        startDate: string;
        endDate: string;
    }
}

interface GetSessionStatsFailedAction {
    type: typeof GET_SESSION_STATS_FAILED;
    payload: string;
}

interface GetSessionStatsSucceededAction {
    type: typeof GET_SESSION_STATS_SUCCEEDED;
    payload: DeviceData;
}

export type GetSessionStatsActionTypes = GetSessionStatsAction | GetSessionStatsFailedAction | GetSessionStatsSucceededAction
