import React, { PureComponent, ReactNode } from "react";
import { Box, Typography } from '@mui/material';
import { Cell, Pie, PieChart, ResponsiveContainer } from 'recharts';
import { Theme } from "../../../../../Common/Config/Theme";
import { i18n } from "../../../../../Configuration/I18n";

interface Props {
    title: string;
    webUsers: number;
    mobileUsers: number;
    total: number | undefined;
}

interface State {
    activeIndex: number;
}

export class UsersStatsPieChart extends PureComponent<Props, State> {

    constructor(props: Props) {
        super(props)
        this.state = {
            activeIndex: 0
        }
    }

    render(): ReactNode {
        return (
            <Box className={'statistics-grid card card-chart'}>
                <ResponsiveContainer width={100} height={100}>
                    <PieChart width={100} height={100}>
                        <Pie activeIndex={this.state.activeIndex}
                             data={this.generateData()}
                             cx="50%"
                             cy="50%"
                             innerRadius={25}
                             outerRadius={40}
                             fill="#8884d8"
                             dataKey="value"
                             onMouseEnter={(children: ReactNode, index: number) => this.onPieEnter(children, index)}>

                            <Cell key={'cell-1'} fill={Theme.darkGreen}/>
                            <Cell key={'cell-2'} fill={Theme.red}/>
                        </Pie>
                    </PieChart>
                </ResponsiveContainer>

                <Box sx={{ml: 1}}>
                    <Typography>{this.calculatePercentage(this.props.mobileUsers + this.props.webUsers)}</Typography>

                    <Typography component={'span'} className={'light-style'}>
                        <Typography component={'b'}>{this.calculatePercentage(this.props.mobileUsers)}</Typography>
                        ({i18n.statistics.mobile}) et
                        <Typography component={'b'}> {this.calculatePercentage(this.props.webUsers)}</Typography>
                        ({i18n.statistics.web})
                    </Typography>

                    <Typography component={'span'} className={'bold-style'}>{this.props.title}</Typography>
                </Box>
            </Box>
        )
    }

    private calculatePercentage(users: number): string {
        if (this.props.total && this.props.total !== 0) {
            const options = {style: 'percent', maximumFractionDigits: 2};
            const numberFormat = new Intl.NumberFormat('fr-FR', options);
            return numberFormat.format(users / this.props.total).replace(/\s/g, '  ');
        } else {
            return "..."
        }
    }

    private generateData(): Array<{ value: number }> {
        if (this.props.mobileUsers && this.props.total && this.props.webUsers) {
            const webValue = this.props.webUsers > 0 ? this.props.webUsers / this.props.total : 0
            const mobileValue = this.props.mobileUsers > 0 ? this.props.mobileUsers / this.props.total : 0

            return mobileValue + webValue < 1 ? [
                {value: webValue + mobileValue},
                {value: 1 - mobileValue - webValue},
            ] : [
                {value: 1}
            ]
        }
        return [];
    }

    private onPieEnter = (e: ReactNode, index: number) => {
        this.setState({
            activeIndex: index
        })
    }
}
