import { Box, Grid } from "@mui/material";
import { PercentageCard } from "../../../Component/PercentageCard";
import { i18n } from "../../../../../../Configuration/I18n";
import { StatisticsCard } from "../../../Component/Card";
import React, { PureComponent } from "react";
import { AdventureData } from "../../../../../Domain/Entity/AdventureData";

interface Props {
    adventureStats: AdventureData
}

export class InterAgencyAdventureProgressStats extends PureComponent<Props> {
    render() {
        return (
            <Box className={'inter-agency-goal'}>
                <Grid container spacing={2} mb={3}>
                    <Grid item xs={12} lg={3}>
                        <PercentageCard title={i18n.statistics.turnover_rate}
                                        numerator={this.props.adventureStats.totalTurnover}
                                        denominator={this.props.adventureStats.totalTurnoverGoal}
                        />
                    </Grid>

                    <Grid item xs={12} lg={3}>
                        <StatisticsCard type={'CURRENCY'} title={i18n.statistics.turnover_value}
                                        value={this.props.adventureStats.totalTurnover.toString()}/>
                    </Grid>

                    <Grid item xs={12} lg={3}>
                        <StatisticsCard type={'CURRENCY'} title={i18n.statistics.old_ca}
                                        value={this.props.adventureStats.totalTurnoverGoal.toString()}/>
                    </Grid>

                    <Grid item xs={12} lg={3}>
                        <StatisticsCard title={i18n.statistics.activated_client_value}
                                        value={this.props.adventureStats.totalActivatedClient.toString()}/>
                    </Grid>
                </Grid>
            </Box>
        )
    }
}
