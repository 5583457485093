import { Adventure } from "../../../../Domain/Entity/Adventure";
import { AdventureDTO } from '../DTO/Adventure.DTO';
import { AdventureBuilder } from "../../../../Domain/Builder/Adventure.builder";
import { ApplicationContext } from "../../../../../Configuration/Application.context";
import { MCQDto } from "../DTO/MCQDto";
import { MCQ } from "../../../../Domain/Entity/MCQ";
import { MCQBuilder } from "../../../../Domain/Builder/MCQ.builder";
import { MCQQuestion } from "../../../../Domain/Entity/MCQQuestion";
import { MCQResponse } from "../../../../Domain/Entity/MCQResponse";
import { MCQResponseBuilder } from "../../../../Domain/Builder/MCQResponse.builder";
import { MCQQuestionBuilder } from "../../../../Domain/Builder/MCQQuestion.builder";

const moment = ApplicationContext.getInstance().momentJs()

export class AdventureMapper {

    static mapDataToAdventureList(adventures: AdventureDTO[]): Adventure[] {
        return adventures.map(adventure =>
            new AdventureBuilder()
                .withId(adventure.id)
                .withName(adventure.label)
                .withType(adventure.type)
                .withDetails(adventure.details)
                .withStartDate(moment(adventure.startDateTime))
                .withEndDate(moment(adventure.endDateTime))
                .withPictureName(adventure.picture)
                .withEnabled(adventure.enabled)
                .withReward(adventure.reward)
                .build()
        )
    }

    static mapDataToMCQ(mcqDto: MCQDto): MCQ {
        const questions: MCQQuestion[] = mcqDto.questions.map(question => {
            const responses: MCQResponse [] = question.responses.map(response =>
                new MCQResponseBuilder()
                    .withText(response.text)
                    .withId(response.id)
                    .withIsCorrect(response.correct)
                    .build())
            return new MCQQuestionBuilder()
                .withId(question.id)
                .withText(question.text)
                .withDescription(question.description)
                .withPictureName(question.picture !=='NO_IMAGE' ? question.picture : '')
                .withResponses(responses)
                .build()
        })
        return new MCQBuilder()
            .withTitle(mcqDto.title)
            .withId(mcqDto.id)
            .withReward(mcqDto.reward)
            .withQuestions(questions)
            .build()
    }

}
