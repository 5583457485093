import { CartesianData } from "./CartesianData";

export class EmployeeData {
    constructor(
        protected _employees: CartesianData[],
        protected _mobileUsers: CartesianData[],
        protected _webUsers: CartesianData[]
    ) {
    }

    getEmployeeCount(): number {
        return this._employees.reduce((partialSum, item) => partialSum + item.value, 0)
    }

    getMobileUsersCount(): number {
        return this._mobileUsers.reduce((partialSum, item) => partialSum + item.value, 0)
    }

    getWebUsersCount(): number {
        return this._webUsers.reduce((partialSum, item) => partialSum + item.value, 0)
    }

    getTotalUsersCount(): number {
        return this.getWebUsersCount() + this.getMobileUsersCount()
    }

    get mobileUsers(): CartesianData[] {
        return this._mobileUsers;
    }

    get webUsers(): CartesianData[] {
        return this._webUsers;
    }

    get employees(): CartesianData[] {
        return this._employees;
    }
}
