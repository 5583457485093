import { ActionsObservable, Epic, ofType, StateObservable } from 'redux-observable';
import { of } from 'rxjs';
import { catchError, concatMap, switchMap } from 'rxjs/operators';
import { loadAdventureListFailed, loadAdventureListSucceeded } from './actions';
import { AdventureService } from "../../Domain/Gateway/Adventure.service";
import { LoadAdventureListState } from '../../Config/State';
import { LOAD_ADVENTURE_LIST, LoadAdventureListAction } from "./actionTypes";
import { Adventure } from "../../Domain/Entity/Adventure";
import { getAdventureStats } from "../../../Statistics/Usecases/GetAdventureStats/actions";
import { getMcqStats } from "../../../Statistics/Usecases/GetMcqStats/actions";
import { getAdventureSubscriptionStats } from "../../../Statistics/Usecases/GetAdventureSubscriptionStats/actions";

export const loadAdventureListEpic: Epic = (action$: ActionsObservable<LoadAdventureListAction>,
                                            store: StateObservable<LoadAdventureListState>,
                                            { adventureService }: { adventureService: AdventureService }) =>
    action$.pipe(
        ofType(LOAD_ADVENTURE_LIST),
        switchMap(() => adventureService.getAdventureList()
            .pipe(
                concatMap((adventures: Adventure[]) => {
                    if (adventures.length > 0)
                        return [
                            loadAdventureListSucceeded(adventures),
                            getAdventureStats(adventures[0].id, adventures[0].type),
                            getMcqStats(adventures[0].id),
                            getAdventureSubscriptionStats(adventures[0].id)
                        ]
                    else
                        return [
                            loadAdventureListSucceeded(adventures)
                        ]
                }),
                catchError((error: string) => of(loadAdventureListFailed(error)))
            )
        )
    )
