import { CartesianData } from "../Entity/CartesianData";
import { EmployeeData } from "../Entity/EmployeeData";

export class EmployeeDataBuilder {
    protected _employees: CartesianData[]
    protected _mobileUsers: CartesianData[]
    protected _webUsers: CartesianData[]

    withEmployees(employees: CartesianData[]): EmployeeDataBuilder {
        this._employees = employees
        return this
    }

    withMobileUsers(users: CartesianData[]): EmployeeDataBuilder {
        this._mobileUsers = users
        return this
    }

    withWebUsers(users: CartesianData[]): EmployeeDataBuilder {
        this._webUsers = users
        return this
    }

    build(): EmployeeData {
        return new EmployeeData(
            this._employees,
            this._mobileUsers,
            this._webUsers
        )
    }
}
