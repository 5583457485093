import React, { PureComponent, ReactNode } from 'react'
import { AdventureData } from "../../../../Domain/Entity/AdventureData";
import { AdventureType } from "../../../../../Adventure/Domain/Entity/Adventure";
import StandardAdventureScore from "./StandardAdventure/StandardAdventureScore";
import { InterAgenceAdventureScore } from "./InterAgencyAdventure/InterAgenceAdventureScore";

interface Props {
    adventureStats: AdventureData;
    adventureType: AdventureType | undefined;
}

export class AdventureScoreStats extends PureComponent<Props> {
    render(): ReactNode {
        if (this.props.adventureType === 'STANDARD') {
            return <StandardAdventureScore adventureStats={this.props.adventureStats}/>
        }
        if (this.props.adventureType === 'INTER_AGENCE') {
            return <InterAgenceAdventureScore adventureStats={this.props.adventureStats}/>
        }
    }
}
