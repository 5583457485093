import { GetAppStatsState } from "../../Config/State";
import {
    GET_LOGIN_STATS,
    GET_LOGIN_STATS_FAILED,
    GET_LOGIN_STATS_SUCCEEDED,
    GetLoginStatsActionTypes
} from './actionTypes';
import { CartesianDataBuilder } from "../../Domain/Builder/CartesianData.builder";
import { CartesianData } from "../../Domain/Entity/CartesianData";

const initialState: GetAppStatsState = {
    loading: false,
    stats  : null,
    error  : undefined
}

export const getLoginStatsReducer = (state = initialState, action: GetLoginStatsActionTypes): GetAppStatsState => {
    switch (action.type) {
        case GET_LOGIN_STATS:
            return {
                loading: true,
                stats  : null,
                error  : undefined
            }
        case GET_LOGIN_STATS_SUCCEEDED:
            return {
                loading: false,
                stats  : cumulateLoginStats(action.payload),
                error  : undefined
            }
        case GET_LOGIN_STATS_FAILED:
            return {
                loading: false,
                stats  : null,
                error  : action.payload
            }
        default:
            return state
    }
}

const cumulateLoginStats = (data: CartesianData[]): CartesianData[] => {
    let total= 0
    return data.map(item => {
        total = total + item.value
        return new CartesianDataBuilder()
            .withLabel(item.label)
            .withValue(total)
            .build()
    })
}
