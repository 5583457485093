import { CartesianDataDTO } from "../DTO/CartesianData.DTO";
import { SeriesDataDTO } from "../DTO/SeriesData.DTO";
import { CartesianData } from "../../../../Domain/Entity/CartesianData";
import { SerieData } from "../../../../Domain/Entity/SerieData";
import { ApplicationContext } from "../../../../../Configuration/Application.context";
import { AdventureAgencyDataDto, AdventureDataDTO, AdventurePlayerDataDto } from "../DTO/AdventureData.DTO";
import { AdventureData } from "../../../../Domain/Entity/AdventureData";
import { AdventureDataBuilder } from "../../../../Domain/Builder/AdventureData.builder";
import { CartesianDataBuilder } from "../../../../Domain/Builder/CartesianData.builder";
import { MultivariateData } from "../../../../Domain/Entity/MultivariateData";
import { MultivariateDataBuilder } from "../../../../Domain/Builder/MultivariateData.builder";
import { i18n } from "../../../../../Configuration/I18n";
import { EmployeeDataDTO } from "../DTO/EmployeeData.DTO";
import { EmployeeData } from "../../../../Domain/Entity/EmployeeData";
import { EmployeeDataBuilder } from "../../../../Domain/Builder/EmployeeData.builder";
import { StatisticsDateHelper } from "./Statistics.helper";
import { AdventureType } from "../../../../../Adventure/Domain/Entity/Adventure";
import { DeviceData } from "../../../../Domain/Entity/DeviceData";
import { DeviceDataDTO } from "../DTO/deviceData.DTO";

const moment = ApplicationContext.getInstance().momentJs()

export class StatisticsMapper {

    static mapToCartesianData(data: CartesianDataDTO[]): CartesianData[] {
        return data.map(stat => (
            new CartesianDataBuilder()
                .withLabel(stat.label)
                .withValue(stat.value)
                .build()
        ))
    }

    static mapToAccumulatedCartesianData(data: CartesianDataDTO[]): CartesianData[] {
        let total = 0
        return data.map(stat => {
            total = total + stat.value
            return (
                new CartesianDataBuilder()
                    .withLabel(stat.label)
                    .withValue(total)
                    .build()
            )
        })
    }

    static mapToSerieData(data: SeriesDataDTO[]): SerieData[] {
        return data.map(series => {
            return new SerieData(
                series.label,
                series.series.map(stat =>
                    new CartesianDataBuilder()
                        .withLabel(stat.label)
                        .withValue(stat.value)
                        .build()
                )
            )
        })
    }

    static mapToEmployeeData(data: EmployeeDataDTO): EmployeeData {
        return new EmployeeDataBuilder()
            .withEmployees(this.mapToCartesianData(data.employees))
            .withMobileUsers(this.mapToCartesianData(data.users.mobile))
            .withWebUsers(this.mapToCartesianData(data.users.web))
            .build()
    }

    static mapToFormattedCartesianData(data: CartesianDataDTO[]): CartesianData[] {
        return data.map(stat => (
            new CartesianDataBuilder()
                .withLabel(moment(stat.label, 'YYYY-MM-DD').format('ddd DD MMM'))
                .withValue(stat.value)
                .build()
        ))
    }

    static mapToAdventureData(data: AdventureDataDTO, adventureType: AdventureType): AdventureData {

        const playersData = getStandardAdventureDataByPercentage(data.players.map(player => player.score))
        const agenciesData = adventureType == 'STANDARD' ?
            getStandardAdventureDataByPercentage(data.agencies.map(player => player.score)) :
            getInterAgenceAdventureDataByPercentage(data.agencies.map(player => player.score))
        const playersByRegionData = getDataByRegion(data.players)
        const agenciesByRegionData = getDataByRegion(data.agencies)

        return new AdventureDataBuilder()
            .withPlayers(playersData)
            .withAgencies(agenciesData)
            .withPlayersByRegion(playersByRegionData)
            .withAgenciesByRegion(agenciesByRegionData)
            .withTotalTurnover(data.totalTurnover)
            .withTotalTurnoverGoal(data.totalTurnoverGoal)
            .withTotalActivatedClient(data.totalActivatedClient)
            .withTotalActivatedClientGoal(data.totalActivatedClientGoal)
            .build()
    }

    static mapDatesToDeviceData(data: DeviceDataDTO, filterDate: string): DeviceData {
        const mobile = this.mapDatesToCartesianData(data.mobile, filterDate)
        const web = this.mapDatesToCartesianData(data.web, filterDate)

        return new DeviceData(mobile, web)
    }

    static mapDatesToCartesianData(data: CartesianDataDTO[], filterDate: string): CartesianData[] {
        let stats: CartesianData[] = []
        const today = moment().format('YYYY-MM-DD')

        if (data.length > 0) {
            const orderedData = data.sort((a, b) => moment(a.label, 'YYYY-MM-DD').valueOf() - moment(b.label, 'YYYY-MM-DD').valueOf())
            const startDate = orderedData[0].label
            let endDate = orderedData[orderedData.length - 1].label

            if (filterDate.length > 0)
                stats = StatisticsDateHelper.getPreviousDatesWithZeroAsValue(filterDate, startDate)

            endDate = StatisticsDateHelper.getNextDay(endDate)
            stats = [
                ...stats,
                ...StatisticsDateHelper.addMissingDates(orderedData),
                ...StatisticsDateHelper.getPreviousDatesWithZeroAsValue(endDate, today)
            ]
        } else if (filterDate.length > 0) {
            stats = StatisticsDateHelper.getPreviousDatesWithZeroAsValue(filterDate, today)
        }

        return stats
    }

}

const getStandardAdventureDataByPercentage = (data: number[]): CartesianData[] => {
    const values: number[] = [0, 0, 0, 0]
    const labels: string[] = [i18n.statistics.more_than_100, i18n.statistics.between_80_and_100, i18n.statistics.between_50_and_80, i18n.statistics.less_thEn_50]

    data.map(score => {
        if (score >= 1)
            values[0] = values[0] + 1
        else if (score >= 0.8 && score < 1)
            values[1] = values[1] + 1
        else if (score >= 0.5 && score < 0.8)
            values[2] = values[2] + 1
        else
            values[3] = values[3] + 1
    })

    return values.map((value, index) => {
        return (
            new CartesianDataBuilder()
                .withLabel(labels[index])
                .withValue(value)
                .build()
        )
    })
}


const getInterAgenceAdventureDataByPercentage = (data: number[]): CartesianData[] => {
    const values: number[] = [0, 0, 0, 0,0]
    const labels: string[] = [
        i18n.statistics.less_than_100,
        i18n.statistics.between_100_and_110, i18n.statistics.between_110_and_115,
        i18n.statistics.between_115_and_120, i18n.statistics.more_than_120]

    data.map(score => {
        if (score < 1)
            values[0] = values[0] + 1
        else if (score > 1 && score < 1.1)
            values[1] = values[1] + 1
        else if (score > 1.1 && score < 1.15)
            values[2] = values[2] + 1
        else if (score > 1.15 && score < 1.2)
            values[3] = values[3] + 1
        else if (score > 1.2)
            values[4] = values[4] + 1
    })

    return values.map((value, index) => {
        return (
            new CartesianDataBuilder()
                .withLabel(labels[index])
                .withValue(value)
                .build()
        )
    })
}

const getDataByRegion = (data: AdventurePlayerDataDto[] | AdventureAgencyDataDto[]): MultivariateData[] => {
    const dataByRegion: MultivariateData[] = []

    data.map(item => {
        const index = dataByRegion.findIndex(region => region.label === item.regionLabel)

        if (index > -1)
            dataByRegion[index] = new MultivariateDataBuilder()
                .withLabel(dataByRegion[index].label)
                .withValueA(dataByRegion[index].valueA + item.turnover)
                .withValueB(dataByRegion[index].valueB + item.turnoverGoal)
                .build()
        else
            dataByRegion.push(
                new MultivariateDataBuilder()
                    .withLabel(item.regionLabel)
                    .withValueA(item.turnover)
                    .withValueB(item.turnoverGoal)
                    .build()
            )
    })
    return dataByRegion.sort((a, b) => compare(a, b))
}

const compare = (a: MultivariateData, b: MultivariateData): number => {
    if (a.label < b.label)
        return -1
    if (a.label > b.label)
        return 1
    return 0
}
