import { connect } from 'react-redux';
import { Dispatch } from "redux";
import { AppState } from "../../../../Redux-configuration/AppState";
import { AdventureStatisticsContainer } from "./AdventureStatistics.container";
import {
    adventureStatsSelector,
    getAdventureStatsLoadingSelector
} from "../../../Usecases/GetAdventureStats/selector";
import { AdventureData } from "../../../Domain/Entity/AdventureData";
import { loadAdventureList } from "../../../../Adventure/Usecases/List/actions";
import { LoadAdventureListActionTypes } from "../../../../Adventure/Usecases/List/actionTypes";
import { Adventure, AdventureType } from "../../../../Adventure/Domain/Entity/Adventure";
import { adventureListSelector, loadAdventureListLoadingSelector } from "../../../../Adventure/Usecases/List/selector";
import { GetAdventureStatsAction } from "../../../Usecases/GetAdventureStats/actionTypes";
import { getAdventureStats } from "../../../Usecases/GetAdventureStats/actions";
import { mcqStatsSelector } from "../../../Usecases/GetMcqStats/selector";
import { GetMcqStatsAction } from "../../../Usecases/GetMcqStats/actionTypes";
import { getMcqStats } from "../../../Usecases/GetMcqStats/actions";
import { SerieData } from "../../../Domain/Entity/SerieData";
import { adventureSubscriptionStatsSelector } from "../../../Usecases/GetAdventureSubscriptionStats/selectors";
import { GetAdventureSubscriptionStatsAction } from "../../../Usecases/GetAdventureSubscriptionStats/actionTypes";
import { getAdventureSubscriptionStats } from "../../../Usecases/GetAdventureSubscriptionStats/actions";
import { SetTopBarTitleAction } from "../../../../Common/Usecases/SetConfigurationApp/actionTypes";
import { setTopBarTitle } from "../../../../Common/Usecases/SetConfigurationApp/actions";
import { EmployeeData } from "../../../Domain/Entity/EmployeeData";
import { employeeStatsSelector } from "../../../Usecases/GetEmployeeStats/selector";
import { GetEmployeeStatsAction } from "../../../Usecases/GetEmployeeStats/actionTypes";
import { getEmployeeStats } from "../../../Usecases/GetEmployeeStats/actions";

interface StateToPropsType {
    adventureList: Adventure[] | null;
    adventureStats: AdventureData | null;
    mcqStats: SerieData[] | null;
    adventureSubscriptionStats: SerieData[] | null
    loading: boolean;
    employeeStats: EmployeeData | null
}

interface DispatchToPropsType {
    loadAdventureList: () => void;
    getAdventureStats: (adventureId: string, adventureType: AdventureType) => void;
    getMcqStats: (adventureId: string) => void;
    getAdventureSubscriptionStats: (adventureId: string) => void;
    setTopBarTitle: (title: string) => void
    getEmployeeStats: () => void
}

const mapStateToProps = (state: AppState): StateToPropsType => ({
    adventureList             : adventureListSelector(state),
    adventureStats            : adventureStatsSelector(state),
    mcqStats                  : mcqStatsSelector(state),
    loading                   : getAdventureStatsLoadingSelector(state) || loadAdventureListLoadingSelector(state),
    adventureSubscriptionStats: adventureSubscriptionStatsSelector(state),
    employeeStats             : employeeStatsSelector(state)
})

const mapDispatchToProps = (dispatch: Dispatch): DispatchToPropsType => ({
    loadAdventureList            : (): LoadAdventureListActionTypes => dispatch(loadAdventureList()),
    getAdventureStats            : (adventureId: string, adventureType: AdventureType): GetAdventureStatsAction => dispatch(getAdventureStats(adventureId, adventureType)),
    getMcqStats                  : (adventureId: string): GetMcqStatsAction => dispatch(getMcqStats(adventureId)),
    getAdventureSubscriptionStats: (adventureId: string): GetAdventureSubscriptionStatsAction => dispatch(getAdventureSubscriptionStats(adventureId)),
    setTopBarTitle               : (title: string): SetTopBarTitleAction => dispatch(setTopBarTitle(title)),
    getEmployeeStats             : (): GetEmployeeStatsAction => dispatch(getEmployeeStats())
})

export const AdventureStatisticsPage = connect(mapStateToProps, mapDispatchToProps)(AdventureStatisticsContainer)
