import React, { PureComponent, ReactNode } from 'react'
import { SerieData } from "../../../../Domain/Entity/SerieData";
import { Box, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { i18n } from "../../../../../Configuration/I18n";
import { CartesianData } from "../../../../Domain/Entity/CartesianData";
import { StatisticsGridTitle } from "../../Component/Title";
import { StatisticsBadgeDetails } from "./Badge/Badge";
import { Theme } from "../../../../../Common/Config/Theme";
import { Region } from "../../../../../Agence/Domain/Entities/Region";

interface DataTableBadgeType {
    badge: string
    regions: CartesianData[]
    total: number
}

interface Props {
    title: string
    stats: SerieData[]
    regions: Region[] | null
}

export class StatisticsBadgesTable extends PureComponent<Props> {

    render(): ReactNode {
        return (
            <Grid container spacing={3} mb={3}>
                <Grid item xs={12}>
                    <Box className={'statistics-grid'}>
                        <StatisticsGridTitle title={this.props.title}
                                             info={this.props.title}/>
                        <TableContainer component={Paper}>
                            <Table sx={{minWidth: 650}} size="small" aria-label="a dense table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>{i18n.statistics.badges}</TableCell>

                                        {this.renderRegions()}

                                        <TableCell align="center">{i18n.statistics.quantity}</TableCell>
                                    </TableRow>
                                </TableHead>

                                <TableBody>
                                    {this.renderTableBody()}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                </Grid>
            </Grid>
        )
    }

    renderRegions() {
        return this.props.stats.map(item => {
            const region = this.props.regions?.find(element => element.regionName === item.label)
            const title = region?.regionLabel + '\n(' + item.label + ')'
            return <TableCell key={item.label} align="center">{title}</TableCell>
        })
    }

    renderTableBody() {
        return this.formatDataTable().map(stat => (
            <TableRow key={stat.badge}
                      sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                <TableCell style={{lineHeight: '12px'}}>
                    <StatisticsBadgeDetails name={stat.badge}/>
                </TableCell>

                {this.props.stats.map(item => this.renderCellValueBadge(item.label, stat.regions))}

                <TableCell align="center" style={{color: Theme.lightOrange}}>
                    {stat.total}
                </TableCell>
            </TableRow>
        ))
    }

    renderCellValueBadge(badge: string, data: CartesianData[]): ReactNode {
        const cell: CartesianData | undefined = data.find(region => region.label === badge)
        if (cell)
            return <TableCell align="center" key={cell.label}>{this.percentBadgeByRegion(cell.value, data)}</TableCell>
        else
            return <TableCell align="center" key={badge}>0</TableCell>
    }

    formatDataTable(): DataTableBadgeType[] {

        let dataTable: DataTableBadgeType[] = []
        this.props.stats.map((serie: SerieData) => {
            serie.series.map((badge: CartesianData) => {

                const currentBadge = dataTable.find((item) => {
                    return item.badge === badge.label
                })

                if (currentBadge) {
                    dataTable = dataTable.map((item) => {
                        if (item.badge === badge.label) {
                            const currentRegion = currentBadge.regions.find((regionCartesian) => {
                                return regionCartesian.label === serie.label
                            })

                            const region: CartesianData[] = []
                            if (currentRegion) {
                                region.push(new CartesianData(serie.label, badge.value + currentRegion.value))
                            } else {
                                region.push(new CartesianData(serie.label, badge.value))
                            }

                            return {
                                badge  : badge.label,
                                regions: [...region, ...currentBadge.regions],
                                total  : badge.value + item.total,
                            }
                        } else {
                            return item
                        }
                    })
                } else {
                    dataTable.push({
                        badge  : badge.label,
                        regions: [new CartesianData(serie.label, badge.value)],
                        total  : badge.value,
                    })
                }
            })
        })
        return dataTable
    }

    getRegionsRow(regions: CartesianData[], region: string, value: number): CartesianData[] {
        const regionIndex = regions.findIndex(item => item.label === region)
        const totalBadges: number = this.getCountBadge(regions)
        if (regionIndex !== -1)
            regions.splice(regionIndex, 1, new CartesianData(region, Number(Math.round((value / totalBadges) * 100).toFixed(2))))
        else
            regions.push(new CartesianData(region, value))
        return regions
    }

    getCountBadge(data: CartesianData[]): number {
        return data.reduce((partialSum, item) => partialSum + item.value, 0)
    }

    percentBadgeByRegion(value: number, data: CartesianData[]): string {
        const totalBadges: number = this.getCountBadge(data)
        return Math.round((value / totalBadges) * 100) + ' %'
    }
}
