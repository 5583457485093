export const AdventureI18n = {
    adventure_list       : 'Liste des aventures',
    label                : 'Nom de l\'aventure',
    start_date           : 'Date de début',
    id                   : 'Id',
    end_date             : 'Date de fin',
    status               : 'Statut',
    add_adventure        : 'Ajouter une aventure ',
    no_adventure         : 'Pas d\'aventures',
    active               : 'Activée',
    inactive             : 'Désactivé',
    adventure            : 'La mission',
    type                 : 'Type',
    title                : 'Titre',
    details              : 'Description',
    date                 : 'Date',
    picture              : 'Image',
    picture_required     : 'Image *',
    picture_description  : 'Ajouter une image à votre mission',
    save                 : 'Enregistrer',
    mcq_settings         : 'Paramètres de QCM',
    description          : 'Description',
    reward               : 'Récompense',
    reward_bonus         : 'Récompense bonus',
    picture_mcq          : 'Ajouter une image pour les questions de QCM',
    mcq_questions        : 'Questions de QCM',
    add_question         : 'Ajouter une nouvelle question',
    good_response        : 'Bonne réponse',
    bad_response         : 'Mauvaise réponse',
    response             : 'Réponse',
    response_1           : 'Réponse 1',
    response_2           : 'Réponse 2',
    response_3           : 'Réponse 3',
    response_4           : 'Réponse 4',
    question             : 'Question',
    error_good_response  : 'Veuillez ajouter au moins une réponse correct',
    error_empty_response : 'Veuillez ajouter 4 réponses par question',
    save_and_continue    : 'Enregistrer et continuer',
    cancel               : 'Annuler',
    error_questions      : 'Veuillez ajouter 10 questions',
    delete_question_text : 'Voulez-vous vraiment supprimer cette question',
    yes                  : 'Oui',
    no                   : 'Non',
    error_add_adventure  : 'Une erreur est survenue lors d\'ajout d\'une aventure',
    error_adventure_dates: 'Veuillez vérifier les dates de début et fin de l\'aventure',
    update_adventure     : (name: string) => 'Modifier l\'aventure : ' + name,
    picture_details      : (width: number, height: number) => `L'image doit faire ${width} X ${height} pixels et peser moins de 2 Mo.`,
    adventure_title      : 'Mission Challenge',
    update_question      : 'Modifier la question'
}
