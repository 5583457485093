import React, { PureComponent, ReactNode } from 'react'
import { Box, Grid } from "@mui/material";
import { i18n } from "../../../../../Configuration/I18n";
import { PercentageCard } from "../../Component/PercentageCard";
import { StatisticsCard } from "../../Component/Card";
import { CartesianData } from "../../../../Domain/Entity/CartesianData";
import { SerieData } from "../../../../Domain/Entity/SerieData";
import { AreaChartWithLimit } from "../../Component/AreaChartWithLimit";
import { EmployeeData } from "../../../../Domain/Entity/EmployeeData";

interface Props {
    adventureSubscriptionStats: SerieData[] | null
    employeeStats: EmployeeData | null
}

export class AdventureSubscriptionStats extends PureComponent<Props> {

    render(): ReactNode {
        return (
            <Box>
                <Grid container spacing={3} mb={3}>
                    <Grid item xs={12} lg={4}>
                        <PercentageCard title={i18n.statistics.subscription_adventure_rate}
                                        numerator={this.getCountSubscription(this.props.adventureSubscriptionStats)}
                                        denominator={this.props.employeeStats?.getTotalUsersCount()}/>
                    </Grid>

                    <Grid item xs={12} lg={4}>
                        <StatisticsCard title={i18n.statistics.subscription}
                                        value={this.props.adventureSubscriptionStats ? this.getCountSubscription(this.props.adventureSubscriptionStats).toString() : '0'}/>
                    </Grid>

                    <Grid item xs={12} lg={4}>
                        <StatisticsCard title={i18n.statistics.users}
                                        value={this.props.employeeStats ? this.props.employeeStats.getTotalUsersCount().toString() : '0'}/>
                    </Grid>
                </Grid>

                <Grid container spacing={3} mb={3}>
                    <AreaChartWithLimit title={i18n.statistics.adventure_subscription}
                                        limit={this.props.employeeStats ? this.props.employeeStats.getTotalUsersCount() : 0}
                                        limitLabel={i18n.statistics.participants}
                                        showLimit={true}
                                        valueLabel={i18n.statistics.news_subscription}
                                        stats={this.getDataSubscription(this.props.adventureSubscriptionStats)}/>
                </Grid>
            </Box>
        )
    }

    getDataSubscription(data: SerieData[] | null): CartesianData[] {
        const result: CartesianData[] = []
        let total = 0
        data?.map((element: SerieData) => {
            element.series.map(item => {
                total = total + item.value
                return result.push(new CartesianData(item.label, total))
            })
        })
        return result
    }

    getCountSubscription(data: SerieData[] | null): number {
        return data ? data.reduce((partialSum, item) => partialSum + item.accumulatedData(), 0) : 0
    }
}
