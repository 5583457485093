import React, { PureComponent, ReactNode } from 'react'
import { AdventureData } from "../../../../Domain/Entity/AdventureData";
import { AdventureType } from "../../../../../Adventure/Domain/Entity/Adventure";
import { StandardAdventureGoalAchievementStats } from "./StandardAdventure/StandardAdventureGoalAchievementStats";
import {
    InterAgenceAdventureGoalAchievementStats
} from "./InterAgencyAdventure/InterAgenceAdventureGoalAchievementStats";

interface Props {
    adventureStats: AdventureData;
    adventureType: AdventureType | undefined
}

export class AdventureGoalAchievementStats extends PureComponent<Props> {
    render(): ReactNode {
        if (this.props.adventureType === "STANDARD") {
            return <StandardAdventureGoalAchievementStats adventureStats={this.props.adventureStats}
            />
        } else if (this.props.adventureType === "INTER_AGENCE") {
            return <InterAgenceAdventureGoalAchievementStats  adventureStats={this.props.adventureStats}
            />
        }
        return <></>
    }
}
