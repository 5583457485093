import {
    GET_SESSION_STATS,
    GET_SESSION_STATS_FAILED,
    GET_SESSION_STATS_SUCCEEDED,
    GetSessionStatsActionTypes
} from './actionTypes';
import { DeviceData } from "../../Domain/Entity/DeviceData";

export const getSessionStats = (startDate: string, endDate: string): GetSessionStatsActionTypes => ({
    type   : GET_SESSION_STATS,
    payload: {
        startDate,
        endDate
    }
})

export const getSessionStatsFailed = (error: string): GetSessionStatsActionTypes => ({
    type   : GET_SESSION_STATS_FAILED,
    payload: error
})

export const getSessionStatsSucceeded = (data: DeviceData): GetSessionStatsActionTypes => ({
    type   : GET_SESSION_STATS_SUCCEEDED,
    payload: data
})
