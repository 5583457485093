import { CartesianData } from "../../Domain/Entity/CartesianData";

export const GET_LOGIN_STATS = 'GET_LOGIN_STATS'
export const GET_LOGIN_STATS_FAILED = 'GET_LOGIN_STATS_FAILED'
export const GET_LOGIN_STATS_SUCCEEDED = 'GET_LOGIN_STATS_SUCCEEDED'

export interface GetLoginStatsAction {
    type: typeof GET_LOGIN_STATS;
    payload: {
        startDate: string;
        endDate: string;
    }
}

interface GetLoginStatsFailedAction {
    type: typeof GET_LOGIN_STATS_FAILED;
    payload: string;
}

interface GetLoginStatsSucceededAction {
    type: typeof GET_LOGIN_STATS_SUCCEEDED;
    payload: CartesianData[];
}

export type GetLoginStatsActionTypes = GetLoginStatsAction | GetLoginStatsFailedAction | GetLoginStatsSucceededAction
